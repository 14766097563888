* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  background-color: black;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

.hover {
  cursor: pointer;
  width: 3.2rem;
  height: 3.2rem;
}